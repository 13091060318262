import React, { FC } from 'react'
import { PageTemplate } from '../components/pageTemplate'
import { PostHeader } from '../components/postHeader'

// http://www.dktech.cz/?paged=4
// http://www.dktech.cz/?p=199

const PostNoviGamemasters: FC = () => {
  return (
    <PageTemplate
      prevLink="/post_velikonocni_event_amulety_moci"
      nextLink="/post_vyberove_rizeni"
    >
      <PostHeader
        title="Noví gamemasters"
        link="/post_novi_gamemasters"
        author="Algy"
        date="18.03.2014"
        time="13:28"
        category="Oznámení"
      />
      <p style={{ textAlign: 'justify' }}>Zdravím,</p>
      <p style={{ textAlign: 'justify' }}>jak víte, od 20. února 2014 bylo možné podat přihlášku do výběrového řízení na pozici GM na našem serveru. 8. března 2014 jsme přijímání přihlášek zastavili a ty přihlášky, které jsme obdrželi, vyhodnotili.</p>
      <p style={{ textAlign: 'justify' }}>Celkem se přihlásilo 24 uchazečů, přitom kvalita zpracování přihlášek se velmi lišila. &nbsp;Do posuzování se kromě kvality přihlášky promítlo i splnění požadavků, které jsme uvedli v podmínkách výběrového řízení. Výsledkem je, že bylo vybráno celkem sedm adeptů, kteří nejlépe splňují podmínky pro pozici GM. Se třemi byl zahájen výcvik. Bohužel již na samém začátku výcviku se jedno z GM ukázalo jako nevyhovující. Tříčlenný tým byl proto rychle doplněn dalším z adeptů.</p>
      <p style={{ textAlign: 'justify' }}>&nbsp;Nová GM jsou průběžně školena v používání získaných skillů, sjednocujeme hodnocení přestupků proti pravidlům, ujednocujeme si zásady komunikace s hráči, řešíme nápady na nové “denní” eventy. Dnešním dnem počínaje tedy můžete ve hře potkat tři nová GM. Jsou to <strong>GM Bachar, GM Riser a GM Orca</strong>. Všechna tři GM jsou ve zkušební době. Uspějí-li, bude jim přidělen odpovídající equip a vyšší builder.</p>
      <p style={{ textAlign: 'justify' }}>Oficiální představení GM se uskuteční dnes, tedy 18. března 2014 v době od 20:15 do 20:30 hodin v Giranu na náměstí.</p>
      <p style={{ textAlign: 'justify' }}>Chtěl bych touto cestou požádat všechny hráče, aby byli k novým GM shovívaví. Je třeba si uvědomit, že není lehké se s novou pozicí sžít a nepodlehnout jisté moci, která jim byla svěřena.</p>
      <p style={{ textAlign: 'justify' }}>Novým GM bych pak chtěl popřát spousty trpělivosti, nekončící odhodlání a pevnou vůli.</p>
      <p style={{ textAlign: 'justify' }}>
      </p><p style={{ textAlign: 'justify' }}>Za DKTech tým GM Algy</p>


      <div className="cleared">&nbsp;</div>
    </PageTemplate>
  )
}

export default PostNoviGamemasters

export { Head } from '../components/defaultHtmlHead'